<template>
  <div>
    <form-wizard
      ref="active"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      @on-complete="addUser"
    >
      <!-- accoint details tab -->
      <tab-content
        title="Account Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Account Details
              </h5>
              <small class="text-muted">
                Enter Your Account Details.
              </small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="rank"
                rules="required"
              >
                <b-form-group
                  label="Level/Rank"
                  label-for="rank"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="rank"
                    v-model="rank_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="rankOptions"
                    :reduce="val => val.value"
                    @input="getSlabList"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="plan"
                rules="required"
              >
                <b-form-group
                  label="Discount Plan Name"
                  label-for="plan"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="plan"
                    v-model="slab_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="slabOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Mobile No."
                label-for="mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="mobile"
                  rules="required|numeric|startWith|min:10|max:12|uniqueMobile"
                >
                  <cleave
                    id="prefix"
                    v-model="data.mobile"
                    class="form-control"
                    :raw="false"
                    :options="prefix"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email|uniqueEmail"
                >
                  <b-form-input
                    id="email"
                    v-model="data.email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="data.name"
                    placeholder="Enter Name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label=" Account Type"
                label-for="account_type"
              >
                <v-select
                  id="account_type"
                  v-model="account_type"
                  :options="accountTypeOptions"
                  @input="showHideCompany"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="showIsCompany"
              md="6"
            >
              <b-form-group
                label="Company Name"
                label-for="firm_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <b-form-input
                    id="firm_name"
                    v-model="data.firm_name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="showIsCompany"
              md="6"
            >
              <b-form-group
                label="Company Registration No"
                label-for="firm_reg_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Firm registartion number+"
                  rules="required"
                >
                  <b-form-input
                    id="firm_reg_no"
                    v-model="data.firm_reg_no"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Password"
                label-for="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="confirmation"
                >
                  <b-form-input
                    id="password"
                    v-model="data.password"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Confirm Password"
                label-for="Confirm_Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:confirmation"
                >
                  <b-form-input
                    id="Confirm_Password"
                    v-model="data.password_confirmation"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Passport/ID Number"
                label-for="passport_no"
              >
                <b-form-input
                  id="linked-in"
                  v-model="data.passport_no"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- address  -->
      <tab-content
        title="Address"
        icon="feather icon-map-pin"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Address
              </h5>
              <small class="text-muted">Enter Your Address.</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Address1"
                label-for="address1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address1"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="data.address1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Address Lin 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Address2"
                label-for="address2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address2"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="data.address2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Address Lin 2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Postal Code"
                label-for="postcode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postal Code"
                  rules="required"
                >
                  <b-form-input
                    id="postcode"
                    v-model="data.postcode"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    placeholder="Enter Post Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  rules="required"
                >
                  <b-form-input
                    id="city"
                    v-model="data.city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter City Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  label="Country"
                  label-for="country"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="country"
                    v-model="data.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="countryOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="State"
                rules="required"
              >
                <b-form-group
                  label="State"
                  label-for="state"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="State"
                    v-model="data.state"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="stateOptions"
                    :reduce="val => val.value"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- other Info -->
      <tab-content
        title="Other Info"
        icon="feather icon-info"
        :before-change="validationOtherInfo"
      >
        <validation-observer
          ref="otherInfoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Other Info
              </h5>
              <small class="text-muted">Enter Your Other Info</small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of birth"
                label-for="dob"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="data.date_of_birth"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'}"
                  locale="en"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="gender"
                rules="required"
              >
                <b-form-group
                  label="Gender"
                  label-for="gender"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="gender"
                    v-model="gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nationality"
                label-for="Nationality"
              >
                <b-form-input
                  id="Nationality"
                  v-model="data.nationality"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Religion"
                rules="required"
              >
                <b-form-group
                  label="Religion"
                  label-for="Religion"
                  :state="errors.length > 0 ? false:null"
                >
                  <v-select
                    id="Religion"
                    v-model="religion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="religionOptions"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Notification Balance"
                label-for="min_balance"
              >
                <b-form-input
                  id="min_balance"
                  v-model="data.min_balance"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Notification Email"
                label-for="notification_email"
              >
                <b-form-input
                  id="notification_email"
                  v-model="data.notification_email"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import SecureLS from 'secure-ls'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import JwtService from '../../common/jwt.service'
import CommonService from '../../services/common.service'
import UserService from '../../services/user.service'

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    Cleave,
    BFormDatepicker,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      stateOptions: [],
      slabOptions: [],
      rankOptions: [{ value: '', label: 'Select Rank' }],
      countryOptions: [],
      configdateTimePicker: {
        dateFormat: 'Y-m-d',
      },
      showIsCompany: false,
      rank_id: { value: '', label: 'Select Rank' },
      slab_id: '',
      required,
      email,
      state: { value: '', label: ' Select State' },
      country: { value: '', label: ' Select country' },
      account_type: { value: '', label: ' Select Account Type' },
      religionOptions: [
        { value: '', label: 'Select Religion' },
        { value: 'Islam', label: 'Islam' },
        { value: 'Buddhism/Taoism', label: 'Buddhism/Taoism' },
        { value: 'Hinduism', label: 'Hinduism' },
        { value: 'Others', label: 'Others' },
      ],
      accountTypeOptions: [
        { value: '', label: ' Select Account Type' },
        { value: 'individual', label: 'Individual Account' },
        { value: 'company', label: 'Company Account' },
      ],
      gender: { value: '', label: 'Select Gender' },
      genderOptions: [
        { value: '', label: 'Select Gender' },
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
      ],
      religion: { value: '', label: 'Select Religion' },
      prefix: {
        prefix: '60',
      },
      data: {
        spid: JwtService.getUser().loginid,
      },
    }
  },
  computed: {
    activeUserInfo() {
      const user = JwtService.getUser()
      return user
    },

  },
  created() {
    this.getState()
    this.getCountry()
    this.getRankList()
    this.getSlabList()
  },
  mounted() {
    const ls = new SecureLS({
      encodingType: 'aes',
      isCompression: false,
    })
    const isUniqueMobile = value => UserService.validatePhone({ mobile: value, id: ls.get('edit_user_id') }).then(
      response => {
        this.otp = ''
        this.stopTrying = true
        this.success = false
        return {
          valid: response.mobile_valid,
          data: {
            message: response.mobile_message,
          },
        }
      },
      error => {},
    )
    const isuniqueEmail = value => UserService.validateEmail({ email: value }).then(
      response => {
        this.otp = ''
        this.stopTrying = true
        this.success = false
        return {
          valid: response.email_valid,
          message: response.email_message,
          data: {
            message: response.email_message,
          },
        }
      },
      error => {},
    )
    extend('uniqueMobile', {
      validate: isUniqueMobile,
      message: 'Mobile number already exits',
    }, {
      immediate: false,
    })
    extend('uniqueEmail', {

      validate: isuniqueEmail,
      message: 'Email Id already exist',
    }, {
      immediate: false,
    })
    extend('startWith', {
      validate: value => {
        if (this.strncmp(value, '60', 2) !== 0 && this.strncmp(value, '01', 2) !== 0) {
          return false
        }
        return true
      },

      message: 'This mobile must start with 60 or 01',

    })
  },
  methods: {
    strncmp(str1, str2, n) {
      str1 = str1.substring(0, n)
      str2 = str2.substring(0, n)
      return ((str1 == str2) ? 0 : ((str1 > str2) ? 1 : -1))
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationOtherInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.otherInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    showHideCompany() {
      if (this.account_type.value == 'company') {
        this.showIsCompany = true
      } else {
        this.showIsCompany = false
      }
    },
    addUser() {
      this.data.rank_id = this.rank_id
      this.data.slab_id = this.slab_id.value
      this.data.gender = this.gender.value
      this.data.religion = this.religion.value
      this.data.account_type = this.account_type.value

      UserService.addDownlineUser(this.data).then(
        response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User Info Update',
              text: response.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.$router.push('manage/all/users')
        },
        error => {
          const self = this
          if (error.response.data.errors != undefined) {
            Object.entries(error.response.data.errors).map((value, key) => {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: value[1][0],
                  icon: 'AddIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: error.response.data.message,
                icon: 'AddIcon',
                variant: 'danger',
              },
            })
          }
        },
      )
    },
    getSlabList() {
      this.slabOptions = []
      this.slab_id = '',
      UserService.getAuthUserPlanList(this.rank_id).then(
        response => {
          const self = this
          response.user_plan.map((value, key) => {
            self.slabOptions.push(
              { value: value.id, label: value.slab_name },
            )
          })
        },
        error => {},
      )
    },
    getRankList() {
      UserService.getDownlineRankList().then(
        response => {
          const self = this
          response.rank_list.map((value, key) => {
            if (value.id != 16) {
              self.rankOptions.push(
                { value: value.id, label: value.rank_name },
              )
            }
          })
        },
        error => {},
      )
    },
    getState() {
      CommonService.getState().then(
        response => {
          const self = this
          response.state.map((value, key) => {
            self.stateOptions.push(
              { value: value.state, label: value.state },
            )
          })
          this.$refs.active.activateAll()
        },
        error => {},
      )
    },
    getCountry() {
      CommonService.getCoutry().then(
        response => {
          const self = this
          response.country.map((value, key) => {
            self.countryOptions.push(
              { value: value.country, label: value.country },
            )
          })
        },
        error => {},
      )
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
